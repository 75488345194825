import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
} from '@chakra-ui/react'
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import PlainText from '../components/CaseStudies/PlainText'
import PlainImage from '../components/CaseStudies/PlainImage'
import ImageText from '../components/CaseStudies/ImageText'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const KTVAPage = ({ data }) => {
  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)
    }
  }, [])

  return (
    <Layout>
      <SEO title={`KTVA Channel 11 News Website - ${data.site.siteMetadata.title}`} noIndex />
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>KTVA Channel 11 News Website</H1>
      </Container>
      {/** BLOCK 01 */}
      <ImageText
        heading="Brief"
        body="As one of Alaska’s primary news sources, KTVA is a household name in content delivery. At the time of contracting,
        KTVA was a recent purchase of Denali Media, a holding company of GCI, and was in desperate need of a complete revamp. The
        brief to Alopex was simply put: Build a website that is progressive enough to compete on the national stage that we can
        control in entirety."
        img={data.anchorage.childImageSharp.fluid}
        color={colors.brand.lightGray}
        overlayColor="linear-gradient(135deg,rgb(76,81,163) 0%,rgb(31,26,79) 100%)"
        overlayOpacity="0.9"
      />
      {/** BLOCK 02 */}
      <PlainImage img={data.responsive.childImageSharp.fluid} />
      {/** BLOCK 03 */}
      <PlainText
        color={colors.brand.slate}
        bgColor={colors.brand.lightGray}
        heading="Design"
        body="Working with Bradley Reid (now Brilliant Media Strategies), KTVA was one of the first corporate-level responsive
        websites in Alaska. Featuring a dynamic tiling and color system, KTVA brought a fresh face to Alaskan news by focusing
        on ease-of-use and minimal graphics. Because of the amount of content delivered on the website every day, the website
        was very purposefully designed to decrease load times by utilizing visual elements that could be generated by code
        rather than downloaded files."
      />
      {/** Block 04 */}
      <PlainImage img={data.responsive.childImageSharp.fluid} />
      {/** BLOCK 05 */}
      <ImageText
        heading="Development"
        body="At the time, KTVA pushed the functional boundaries of WordPress. Specifically KTVA not only needed to appeal to
        viewers on the front end of the website, but also be usable and simple for the substantial staff of Denali Media. The
        resulting website was a technical behemoth that was fine tuned and maintained by Alopex until its retirement in 2017.
        KTVA featured: Full integration with the Google’s DoubleClick platform for intelligent ad deployment; dynamic weather
        fetching and visualization; live video streaming during broadcasts; custom Mailchimp templates and campaigns; dynamic
        election feeds that auto updated and were visually interpreted into custom themes; as well as a number of special event
        tools, such as Mount Marathon interactive graphics, Iditarod standings, and several sub-sites for Frontiers and blogs.
        The hosting for KTVA was designed to work with Amazon Web Services and successfully sailed through repeated viral events
        using dynamic scaling. KTVA.com was also continuously optimized for Google News by the Alopex team, which became a
        phenomenal source of passive traffic."
        img={data.cuty.childImageSharp.fluid}
        color={colors.brand.lightGray}
        overlayColor="#173db0"
        overlayOpacity="0.9"
      />
      {/** BLOCK 06 */}
      <PlainImage img={data.frontiers.childImageSharp.fluid} />
      {/** BLOCK 07 */}
      <PlainText
        color={colors.brand.slate}
        bgColor={colors.brand.lightGray}
        heading="Results"
        className="lastBlock"
        body="The KTVA overhaul by Alopex was a major driver in an influx of traffic to KTVA by almost 1000% over its lifetime. At the time of retirement, KTVA.com was seeing over a million sessions per month. The site earned an A on both the Pingdom site speed test and the Google responsiveness test and was easily accessible in Alaskan villages—notorious for slow internet access speeds. KTVA was able to successfully claim market share in internet traffic from KTUU (their main competition) due to the progressive design and technology behind the website."
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ktvaPage {
    site {
      siteMetadata {
        title
      }
    }
    anchorage: file(relativePath: { eq: "casestudies/ktva/iStock-899032078-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    responsive: file(relativePath: { eq: "casestudies/ktva/Alopex_KTVA-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    screeny: file(relativePath: { eq: "casestudies/ktva/ALOPEX_KTVA-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cuty: file(relativePath: { eq: "casestudies/ktva/iStock-1127729281.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    frontiers: file(relativePath: { eq: "casestudies/ktva/ALOPEX_KTVA-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default KTVAPage
